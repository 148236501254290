<template>
  <v-dialog v-model="show" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">Siged</v-card-title>
      <v-card-text>
        <div style="text-align:center"><img src="/images/wait.gif"></div>
        <div style="text-align:center">{{ message }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import { VCard, VCardActions, VCardText, VCardTitle, VDialog, VSpacer } from 'vuetify/lib';

export default {
  props: ['visible', 'message'],
  components: {
    //VCard, VCardActions, VCardText, VCardTitle, VDialog, VSpacer
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  }
}
</script>